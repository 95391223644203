import React from 'react';

import classNames from 'classnames';

interface Props {
  className?: string;
  reverse?: boolean;
  children?: React.ReactNode[];
}

const ContentBlock = ({ className, reverse, children }: Props) => {
  if (React.Children.count(children) !== 2) {
    throw new Error('Content block requires two children');
  }
  const [childrenLeft, childrenRight] = children;
  return (
    <div
      className={classNames(
        className,
        `flex flex-col md:flex-row ${reverse ? 'md:flex-row-reverse' : ''}`,
      )}
    >
      <div className="flex-1">{childrenLeft}</div>
      <div className="flex-1">{childrenRight}</div>
    </div>
  );
};

export default ContentBlock;
